@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  height: 100%;
  background: linear-gradient(to bottom, #87CEEB, #1E90FF);
} */


body {
  background: linear-gradient(to bottom, #1E90FF, #87CEEB);
  background-attachment: fixed;
  overflow: hidden;
}